<template>
  <div>
    <div
      class="d-flex align-items-end justify-content-start"
      style="gap: 10px"
      v-if="isSelectAll || selectedItem.length"
    >
      <b-button
        v-if="roleID !== 5"
        variant="outline-danger"
        @click="onSubmitUnPublishSelected"
        :disabled="isLoadingUnPublish"
      >
        <b-spinner small v-if="isLoadingUnPublish"></b-spinner>
        <em class="fa fa-eye-slash" v-else></em>
        UnPublished
      </b-button>
      <label for=""  v-if="roleID !== 5">{{ selectedItem.length }} selected item</label>
    </div>

    <b-table
      sticky-header="600px"
      responsive="xl"
      show-empty
      :busy="isLoading"
      hover
      :isLoading="isLoading"
      :fields="fields"
      :items="books"
      selectable
      ref="tableList"
      @row-selected="onRowSelected"
    >
      <template #head()="row">
        <div class="text-nowrap">
          {{ row.label }}
        </div>
      </template>
      <template #head(no)="{ label }">
        <b-form-checkbox  v-if="roleID !==5"
          class="text-nowrap text-right"
          v-model="isSelectAll"
          @input="onSelectAllRows"
          style="display: table-cell"
        >
          {{ label }}
        </b-form-checkbox>
      </template>
      <template #cell(no)="row">
        <div class="text-nowrap text-right">
          {{ row.rowSelected ? '&check;' : '' }}
          {{ (currentPage - 1) * perPage + row.index + 1 }}
        </div>
      </template>
      <template #cell(title)="row">
        <div style="width: 300px;">
          <router-link
            :to="{ name: DetailItems.name, params: { id: row.item.id } }"
          >
            {{ row.item.name }}
          </router-link>
          <b-button
            size="sm"
            variant="outline"
            @click="copyTextToClipboard(row.item.id)"
          >
            <em class="fa fa-clipboard font-medium" style="font-size: 12px;"> {{ row.item.id }} </em>
          </b-button>
        </div>
      </template>
      <template #cell(printed_price)="row">
        <div
          class="text-right"
          v-if="row.item.printed_price && row.item.printed_currency_code"
        >
          {{
            formatPrice(row.item.printed_price, row.item.printed_currency_code)
          }}
        </div>
      </template>
      <template #cell(item_status)="row">
        <span
          v-if="row.item.item_status == itemStatus.Unpublished"
          class="badge badge-danger p-1"
        >
          {{ row.item.item_status }}
        </span>
        <span
          v-else-if="row.item.item_status == itemStatus.Published"
          class="badge badge-primary p-1"
        >
          {{ row.item.item_status }}
        </span>
        <span
          v-else-if="row.item.item_status == itemStatus.Uploaded"
          class="badge badge-warning p-1"
        >
          {{ row.item.item_status }}
        </span>
        <span v-else class="badge badge-info p-1">
          {{ row.item.item_status }}
        </span>
      </template>
      <template #cell(size)="row">
        <div class="text-right text-nowrap">
          {{ convertMegaBite(row.item.file_size) }}
        </div>
      </template>
      <template #cell(action_ofs)="row">
        <div class="text-right">
          <button
            class="btn btn-sm btn-default"
            @click="actionSendOfs(row.item)"
          >
            <i class="fa fa-sign-in"> </i>
          </button>
        </div>
      </template>
      <template #cell(status)="row">
        <b-dropdown
          split
          split-variant="outline-primary"
          variant="primary"
          text="Actions"
          class="m-2 position-static"
        >
          <b-dropdown-item
            variant="primary"
            v-if="roleID !==5"
            @click="actionUpdateStatus(row.item, 'publish', status.Published)"
          >
            <i class="fa fa-eye"></i>
            Published
          </b-dropdown-item>
          <b-dropdown-item
          v-if=" roleID !==5"
            variant="danger"
            @click="
              actionUpdateStatus(row.item, 'unpublish', status.Unpublished)
            "
          >
            <i class="fa fa-eye-slash"></i>
            UnPublished
          </b-dropdown-item>
          <div v-if="row.item.item_type != item_types_audio">
            <b-dropdown-item
              variant="black"
              @click="
                actionUpdateStatus(
                  row.item,
                  'mcgrawhill content',
                  status.UnpublishedMcgrawhill,
                )
              "
              v-if="permBtnMcGraw()"
            >
              mcgrawhill content
            </b-dropdown-item>
          </div>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item variant="default" @click="actionEdit(row.item)">
            <i class="fa fa-pencil"></i>
            Edit Items
          </b-dropdown-item>
          <div v-if="row.item.item_type == item_types_audio">
            <b-dropdown-item
              variant="default"
              @click="actionEdit({ id: row.item.id, item_type: 'Chapter' })"
            >
              <i class="fa fa-pencil"></i>
              Edit Chapter
            </b-dropdown-item>
          </div>
          <div v-if="!ownVendor.id">
            <b-dropdown-item
              v-if="row.item.item_type == 'book'"
              variant="default"
              @click="actionSendAx(row.item)"
            >
              <i class="fa fa-send"></i>
              Register to AX
            </b-dropdown-item>
            <b-dropdown-item
              v-if="row.item.item_type == 'book'"
              variant="default"
              @click="actionSendAxPremium(row.item.id)"
            >
              <i class="fa fa-send"></i>
              Register to AX (PREMIUM)
            </b-dropdown-item>
          </div>
        </b-dropdown>
      </template>
      <template #cell(vendor_name)="row">
        <div style="width: 200px;">
          {{ row.item ? row.item.vendor : '' }}
        </div>
      </template>
      <template #cell(action_premium)>
        <div class="text-right">
          <button class="btn btn-sm btn-default">
            <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
          </button>
        </div>
      </template>
      <template v-slot:table-busy>
        <div class="text-center text-dark my-2">
          <b-spinner
            class="align-middle"
            variant="dark"
            type="grow"
            label="Loading .."
          >
          </b-spinner>
          Loading ..
        </div>
      </template>
    </b-table>
    <b-modal v-model="showModal" :hide-footer="true" size="md">
      <div class="form-group row">
        <label for="" class="col-md-auto">ID AX</label>
        <div class="col-md-auto">
          <input
            type="number"
            v-model="Form.id_ax"
            id=""
            class="form-control"
          />
        </div>
      </div>
      <div class="form-group row">
        <div class="col-md-3">
          <button class="btn btn-sm btn-success" @click="actionSend">
            <i class="fa fa-sign-in">
              Send OFS
            </i>
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import API from '../../plugins/http';
import moment from 'moment';
import constant from '../../store/constant';
import {
  BookEditPath,
  DetailItems,
  MagazineEditPath,
  NewspaperEditPath,
  AudioEditPath,
  AudioEditChapterPath,
} from '../../router/content';
import { mapActions, mapGetters, mapState } from 'vuex';

const { ROLES } = constant;
const McGrawHill = [1001]; // McGraw-Hill Education

export default {
  name: 'Books',
  props: {
    books: {
      type: Array,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    reloadPage: {
      type: Function,
      required: false,
      default() {},
    },
  },
  data() {
    return {
      showModal: false,
      roleID: JSON.parse(localStorage.getItem("session_auth")).role_id,
      Form: {
        id: null,
        id_ax: null,
      },
      item_types_audio: 'audio book',
      fields: [
        {
          key: 'no',
          label: 'No',
        },
        {
          key: 'title',
          label: 'Name',
        },
        {
          key: 'content_type',
          tdClass: 'text-uppercase',
        },
        {
          key: 'is_active',
          label: 'Is Active',
          formatter: (value) => {
            return value ? 'Yes' : 'No';
          },
          sortByFormatted: true,
          filterByFormatted: true,
        },
        {
          key: 'item_status',
          label: 'Item Status',
          thClass: 'text-capitalize',
          tdClass: 'text-capitalize',
          tdStyle: { width: '250px' },
          sortByFormatted: true,
          filterByFormatted: true,
        },
        {
          key: 'printed_price',
          label: 'Printed Price',
          thClass: 'text-right',
          tdStyle: { width: '300px' },
        },
        {
          key: 'page_count',
          label: 'Page Count',
        },
        {
          key: 'size',
          label: 'Size',
          thClass: 'text-right',
        },
        {
          key: 'release_date',
          label: 'Release Date',
          formatter: (value) => {
            return moment(value).format('DD MMM YYYY');
          },
        },
        {
          key: 'release_schedule',
          label: 'Release schedule',
          formatter: (value) => {
            return moment(value).format('DD MMM YYYY');
          },
        },
        { key: 'vendor_name', label: 'Vendor' },
        { key: 'status', label: 'Action' },
      ],
      DetailItems,
      status: {
        Published: constant.ITEM_STATUS.Published,
        Unpublished: constant.ITEM_STATUS.Unpublished,
        UnpublishedMcgrawhill: constant.ITEM_STATUS.UnpublishedMcgrawhill,
      },
      itemStatus: constant.ITEM_STATUS,
      isSelectAll: false,
      isLoadingUnPublish: false,
      selectedItem: [],
    };
  },
  watch: {
    books() {
      this.isSelectAll = false;
    },
  },
  computed: {
    ...mapGetters('auth', { auth: 'auth' }),
    ...mapState({
      ownVendor: (state) => state.vendors.item,
    }),
  },
  created() {
    this.fetchOwnVendors();
  },
  methods: {
    ...mapActions('vendors', ['fetchOwnVendors']),
    ...mapActions('items', ['bulkUnPublish']),
    formatPrice(value, currency) {
      if (value) {
        var formatter = null;
        if (currency == 'USD') {
          formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: currency,
          });
        } else {
          formatter = new Intl.NumberFormat('en-ID', {
            style: 'currency',
            currency: currency,
          });
        }
        return formatter.format(value);
      }
      return '0';
    },
    convertMegaBite(size) {
      if (size) {
        return (size / 1000 ** 2).toFixed(1) + ' MB';
      }
      return '0';
    },
    actionSend() {
      let payload = {
        id_ax: this.Form.id_ax,
        id: this.Form.id,
      };
      this.$swal.showLoading();
      API.post('ofs-catalog', payload)
        .then((response) => {
          console.log('ini res', response);
          this.$swal({
            icon: 'success',
            toast: true,
            position: 'top-end',
            title: 'Success send ofs',
            timer: 3000,
            text: this.message,
          });
        })
        .catch((error) => {
          console.log('ini error', error);
          this.$swal({
            icon: 'error',
            toast: true,
            position: 'top-end',
            title: 'Failed send ofs',
            timer: 3000,
            text: this.message,
          });
        });
    },
    actionSendOfs(item) {
      this.showModal = true;
      this.Form.id = item.id;
    },
    mapUrl(item) {
      const itemType = constant.ITEM_TYPES;
      if (item.item_type == itemType.BOOK.toLowerCase()) {
        return BookEditPath.name;
      }
      if (item.item_type == itemType.MAGAZINE.toLowerCase()) {
        return MagazineEditPath.name;
      }
      if (item.item_type == itemType.NEWSPAPER.toLowerCase()) {
        return NewspaperEditPath.name;
      }
      if (item.item_type == itemType.AUDIOBOOK.toLowerCase()) {
        return AudioEditPath.name;
      }
      if (item.item_type == 'Chapter') {
        return AudioEditChapterPath.name;
      }
    },
    actionEdit(item) {
      this.$router.push({
        name: this.mapUrl(item),
        params: {
          id: item.id,
        },
      });
    },
    actionSendAx(item) {
      this.$swal.showLoading();
      let payload = {
        id: item.id,
      };
      API.post('register-ax', payload)
        .then(() => {
          this.$swal({
            icon: 'success',
            toast: true,
            position: 'top-end',
            title: 'Success send ax',
            timer: 3000,
          });
        })
        .catch((error) => {
          console.log('ini error', error);
          this.$swal({
            icon: 'error',
            toast: true,
            position: 'top-end',
            title: 'Failed send ax',
            timer: 3000,
          });
        });
    },

    actionSendAxPremium(id) {
      this.$swal.showLoading();
      const payload = {
        id,
      };
      API.post('register-ax/premium', payload)
        .then(() => {
          this.$swal({
            icon: 'success',
            toast: true,
            position: 'top-end',
            title: 'Success send ax premium item.',
            timer: 3000,
          });
        })
        .catch((err) => {
          if (err.response.status == 422) {
            this.$swal({
              icon: 'error',
              toast: true,
              position: 'top-end',
              title: err.response.data.message,
              timer: 3000,
            });
          } else {
            this.$swal({
              icon: 'error',
              toast: true,
              position: 'top-end',
              title: err.response.data.data.message,
              timer: 3000,
            });
          }
        });
    },
    async sendRequestByStatus({ item_id, status, item_type, title }) {
      return API.put(`items/status/${item_id}`, { status, item_type })
        .then((response) => {
          this.$swal({
            icon: 'success',
            toast: true,
            position: 'top-end',
            title: `Success ${title}`,
            timer: 3000,
            text: this.message,
          });
          return response;
        })
        .catch((error) => {
          this.$swal({
            icon: 'error',
            toast: true,
            position: 'top-end',
            title: 'Failed unpublish',
            timer: 3000,
            text: this.message,
          });
          return error;
        });
    },
    actionUpdateStatus(item, title, status) {
      this.$swal({
        title: `Are you sure want to ${title} ?`,
        text: `${item.name} to ${status}`,
        icon: 'warning',
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete?.isConfirmed) {
          await this.sendRequestByStatus({
            item_id: item.id,
            status: status,
            item_type: item.item_type,
            title,
          });
          this.reloadPage();
        }
      });
    },
    permBtnMcGraw() {
      switch (this.auth?.role_id) {
        case ROLES.SUPER_ADMIN:
          return true;
        case ROLES.INTERNAL_ADMIN:
          return true;
        case ROLES.INTERNAL_DATA_ENTRY:
          return true;
        default:
          if (McGrawHill.includes(this.ownVendor?.id)) return true;
          return false;
      }
    },
    findIndexRowMcGrawHill(items = []) {
      const statusMcGrawhill = this.status.UnpublishedMcgrawhill;
      return items
        .map((item, idx) => {
          if (item.item_status == statusMcGrawhill) {
            return idx;
          }
        })
        .filter((val) => ![undefined, null].includes(val));
    },
    unselectRowByIndex(indexItem = []) {
      indexItem.forEach((index) => {
        this.$refs.tableList.unselectRow(index);
      });
    },
    onSelectAllRows(val) {
      if (val == true) {
        this.$refs.tableList.selectAllRows();
      } else {
        this.$refs.tableList.clearSelected();
      }
    },
    onRowSelected(items = []) {
      this.selectedItem = items;
      const indexRowMcGrawHill = this.findIndexRowMcGrawHill(this.books);
      this.unselectRowByIndex(indexRowMcGrawHill);
    },

    async confirmBulkUnPublish() {
      const swalWithBootstrapButtons = this.$swal.mixin({
        customClass: {
          confirmButton: 'btn btn-danger mx-1',
          cancelButton: 'btn btn-primary mx-1',
        },
        buttonsStyling: false,
      });
      const result = await swalWithBootstrapButtons.fire({
        title: 'Are you sure?',
        text: 'this action is quite dangerous, do you still want to continue?',
        icon: 'warning',
        showCancelButton: true,
        reverseButtons: true,
      });

      return result;
    },

    async onSubmitUnPublishSelected() {
      const { isConfirmed = false } = await this.confirmBulkUnPublish();
      if (!isConfirmed) return;

      const items_id = this.selectedItem.map((item) => item.id);
      this.isLoadingUnPublish = true;
      this.bulkUnPublish({
        items_id,
      })
        .then((response) => {
          if (response.status == 201) {
            this.$swal(
              'Success Unpublished',
              `(${items_id.join(', ')})`,
              'success',
            );
          } else {
            this.$swal('Process Failed', `${response}`, 'error');
          }
        })
        .catch(() => {
          const message =
            'Opps .. Something is wrong,please contact your administrator';
          this.$swal('Process Failed', message, 'error');
        })
        .finally(() => {
          this.isLoadingUnPublish = false;
          this.reloadPage();
        });
    },
  },
};
</script>
